// Migrated
<template>
  <client-only>
    <component
      :is="parentElement.type"
      class="login-header nav-item favourites-item dropdown"
      :class="{ searchActive: showSearchform }"
      @mouseover="submenu = true"
      @mouseleave="submenu = false"
    >
      <button
        class="nav-link flex items-center login-item-link"
        :class="{'login-item-link--logged-in': isLoggedIn}"
        :aria-label="$t('authLogIn')"
        @click="$emit('click')"
      >
        <span>
          <slot name="avatar" />
        </span>
      </button>
      <div
        class="dropdown-menu"
        :class="[`dropdown-menu-${drop}`, ...submenu ? ['block'] : []]"
      >
        <div
          v-for="({ label, link }, index) in links"
          :key="'submenu' + index"
        >
          <div
            v-if="label === 'linebreak'"
            class="dropdown-divider"
          />
          <!-- button.dropdown-item(v-else-if="isLogin" @click="$emit('login')") {{ label }}-->
          <nuxt-link
            v-else
            class="dropdown-item"
            :to="link"
          >
            {{ label }}
          </nuxt-link>
        </div>
      </div>
    </component>
  </client-only>
</template>

<script>
import { faUser } from '@fortawesome/pro-duotone-svg-icons'
import { mapState, mapActions } from 'pinia'

import { AuthPages, authPathsUtil } from '@layers/web/utils/auth/auth'

export default defineNuxtComponent({
  setup () {
    const { urls: localeURLs } = useLocale()

    return {
      localeURLs,
    }
  },

  props: {
    showSearchform: {
      type: Boolean,
      default: false,
    },

    isMobile: {
      type: Boolean,
      default: false,
    },

    drop: {
      type: String,
      default: 'left',
    },

    username: {
      type: String,
      default: null,
    },
  },

  emits: ['login', 'click'],

  data () {
    return {
      submenu: false,
      icons: {
        faUser,
      },
    }
  },

  computed: {
    ...mapState(useLocaleStore, ['locale']),
    ...mapState(useUserStore, ['isLoggedIn']),

    parentElement () {
      if (this.isMobile) {
        return {
          type: 'div',
        }
      } else {
        return {
          type: 'div',
        }
      }
    },

    links () {
      const evalLink = key => authPathsUtil(key, this.locale, this.localeURLs)

      if (this.isLoggedIn) {
        const loggedInAsLabel = this.username
          ? this.$t('memberLoggedInAs', { username: this.username })
          : this.$t('memberLoggedIn')
        return [
          {
            'label': this.$t('membersMyBookingsTitle'),
            'link': this.localeURLs.auth + this.localeURLs.authSlug['my-bookings'],
          },
          {
            'label': this.$t('memberFavouritesTitle'),
            'link': this.localeURLs.auth + this.localeURLs.authSlug.favourites,
          },
          {
            'label': this.$t('memberPageTabSettings'),
            'link': this.localeURLs.auth + this.localeURLs.authSlug.settings,
          },
          {
            'label': 'linebreak',
          },
          {
            'label': loggedInAsLabel,
            'link': evalLink(AuthPages.default),
          },
          {
            'label': this.$t('memberLogOut'),
            'link': evalLink(AuthPages.logout),
          },
        ]
      } else {
        const isOnAuthPage = this.$router.currentRoute.value.name === 'auth-slug'
        return [
          {
            'label': this.$t('memberWhatsThisTitle'),
            'link': evalLink(AuthPages.whatsThis),
          },
          {
            'label': 'linebreak',
          },
          {
            'label': this.$t('memberSignup'),
            'link': evalLink(AuthPages.signup),
          },
          {
            'label': this.$t('memberLogIn'),
            // 'link': isOnAuthPage ? evalLink(AuthPages.login) : '#',
            'link': evalLink(AuthPages.login),
            'isLogin': !isOnAuthPage,
          },
        ]
      }
    },
  },

  watch: {
    submenu (v) {
      if (this.isMobile) {
        this.setAlertHidden(v)
      }
    },
  },

  methods: {
    ...mapActions(useMenuStore, {
      setAlertHidden: 'SET_ALERT_HIDDEN',
    }),
  },
})
</script>
